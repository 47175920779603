export const sliderSettings = {
    slidesPerView: 1,
    spaceBetween:50,
    breakpoints:{
        450: {
            slidesPerView: 1
        },
        550:{
            slidesPerView:2
        },
        730:{
            slidesPerView:3
        }
        
    }
}